<template>
  <div>
    <div v-if="loading">
      <!-- Loader component -->
      <Loader />
    </div>
    <div v-else-if="pointsHistory.length === 0">
      <!-- No smart items available -->
      <div class="no-items-available">No points history</div>
    </div>
    <div v-else>
      <div class="first" v-if="pointsHistory.length > 0">
        <p class="history">Points History</p>
        <!-- <div v-for="(entryGroup, index) in groupedHistory" :key="index" class="third">
            <div class="fourth">
              <p v-if="index === 0 || entryGroup[0].date !== groupedHistory[index - 1][0].date"
                class="fifth">{{ formatDate(entryGroup[0].date) }}</p>
            </div>
            <div v-for="(entry, i) in entryGroup" :key="i"
              class="sixth">
              <p class="seventh">{{ entry.type }}</p>
              <p class="eight">{{ entry.description }}</p>
              <p>{{ entry.points }} points</p>
            </div>
        </div> -->
        <div class="third">
          <!-- <div class="fourth">
            <p v-if="index === 0 || entryGroup[0].date !== groupedHistory[index - 1][0].date"
              class="fifth">{{ formatDate(entryGroup[0].date) }}</p>
          </div> -->
          <div v-for="(history, i) in pointsHistory" :key="i"
            class="sixth">
            <p class="seventh" :class="[history.transactiontype == 'debit' ? 'debitType' : 'creditType']">{{ getFirstLetter(history.transactiontype) }}</p>
            <div style="width: 66%; text-align: left; padding-left: 10px;">
              <p class="eight">{{ history.description }}</p>
              <p class="eight">{{ formatDate(history.created_at) }}</p>
            </div>
            <p>{{ (history.amount) }} points</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { capitalize } from "@vue/shared";
import transactions from "../services/transactions";
import Loader from "./Loader";

export default {
  components: {
    Loader
  },
  props: {
    userIDD: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      pointsHistory: [],
      history: [
        { date: 'January 17, 2024', type: 'TC', description: 'Team commission from subscription', points: '4.00' },
        { date: 'January 17, 2024', type: 'DC', description: 'Direct commission from subscription', points: '4.00' },
        { date: 'January 17, 2024', type: 'TC', description: 'Team commission from subscription', points: '4.00' },
        { date: 'January 17, 2024', type: 'DC', description: 'Direct commission from subscription', points: '4.00' },
        { date: 'January 12, 2024', type: 'TC', description: 'Team commission from subscription', points: '4.00' },
        { date: 'January 12, 2024', type: 'DC', description: 'Direct commission from subscription', points: '4.00' },
        { date: 'January 12, 2024', type: 'TC', description: 'Team commission from subscription', points: '4.00' },
        { date: 'January 12, 2024', type: 'DC', description: 'Direct commission from subscription', points: '4.00' },
        { date: 'January 7, 2024', type: 'TC', description: 'Team commission from subscription', points: '4.00' },
        { date: 'January 7, 2024', type: 'DC', description: 'Direct commission from subscription', points: '4.00' },
        { date: 'January 7, 2024', type: 'TC', description: 'Team commission from subscription', points: '4.00' },
        { date: 'January 7, 2024', type: 'DC', description: 'Direct commission from subscription', points: '4.00' },
        { date: 'January 7, 2024', type: 'DC', description: 'Direct commission from subscription', points: '4.00' },
      ]
    };
  },
  computed: {
    groupedHistory() {
      // Group history entries by date
      const grouped = this.history.reduce((acc, entry) => {
        if (!acc[entry.date]) {
          acc[entry.date] = [];
        }
        acc[entry.date].push(entry);
        return acc;
      }, {});

      // Convert object to array of arrays
      return Object.values(grouped);
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    },
    getFirstLetter(fname) {
      return fname.charAt(0); // Returns the first letter of the string
    },
    async pointsTransactionHistory() {
      this.loading = true;
      try {
        await transactions.convertPointTransactions(this.userIDD).then((data) => {
          // console.log("Transaction history", data);
          this.loading = false;
          if (data.success) {
            this.pointsHistory = data.pointlog;

          }
        }).catch((error) => {
          this.loading = false;
          console.log(error);
        // this.$toast.error("please check your network and refresh the page");
      })
      } finally { 
        this.loading = false;
      }
    },
  },
  mounted() {
    this.pointsTransactionHistory();
  },
};
</script>

<style scoped>
.no-items-available{
  align-items: center;
  justify-content: center;
  flex: content;
  padding: 20px;
  color: #555C74;

}
.first {
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.history {
  padding: 0.5rem;
  color: #555C74;
  font-weight: bold;
  box-shadow: 0 4px 2px -5px gray;
}

.third {
  margin-bottom: 1rem;
  padding: 0.5rem;
  max-height: 624px;
  overflow-x: scroll;
}

.fourth{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.fifth{
  font-weight: bold;
  color: #555C74;
}

.sixth{
  display: flex;
  justify-content: space-between;
  font-size: 0.70rem; 
  align-items: flex-start;
  color: #555C74; 
  margin-top: 1.5rem; 
  cursor: pointer;
}

.seventh{
  font-weight: bold;
  background-color: #F5F7FE; 
  padding: 0.4rem 0.5rem;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  /* margin: auto !important; */
  text-align: center;
  text-transform: uppercase;
}

.eight{
  white-space: normal;
  line-height: 1.5; 
  /* width: 66%; */
  padding-left: 2px;
}
.debitType {
  color: #EB0000;
}
.creditType {
  color: #2BC300;
}
</style>